html {
  height: 100%;
  min-height: -webkit-fill-available;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  min-height: -webkit-fill-available;
  /* min-height: calc(100vh - 56px); */
  position: relative;
  margin: 0;
}

.container {
  padding-top: 50px;

  display: block;
}
.max-w-container {
  max-width: 1480px;
  padding: 0rem 1rem;
  width: 100%;
}

.responsive-container {
  margin-top: -90px;
}
.padded-container {
  height: calc(100vh);
  padding-top: 50px;
  display: relative;
}

.stn-box {
  width: 700px;
}

.flexbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stn-margin-child > * {
  margin: 1rem 0px;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -1rem; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 1rem; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 1rem;
}

/** Fonts*/
.white-txt {
  color: #fff;
}

.bold {
  font-weight: bold;
}

/** Animation CSS */

.slide-up-fade-in {
  animation: slide-up-fade-in ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;

  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: slide-up-fade-in ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: slide-up-fade-in ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: slide-up-fade-in ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: slide-up-fade-in ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/

  opacity: 0;
  opacity: 1\9;
}

@keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -moz-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -o-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -ms-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}

.fade {
  animation: fade ease 2s;
  animation-iteration-count: 1;

  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: fade ease 2s;
  -webkit-animation-iteration-count: 1;

  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: fade ease 2s;
  -moz-animation-iteration-count: 1;

  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: fade ease 2s;
  -o-animation-iteration-count: 1;

  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: fade ease 2s;
  -ms-animation-iteration-count: 1;

  -ms-animation-fill-mode: forwards; /*IE 10+*/

  opacity: 0;
  opacity: 1\9;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.delay-1 {
  animation-delay: 150ms;
}
/** Media */
@media screen and (min-width: 600px) {
  .container {
    padding-top: 90px;
  }
  .padded-container {
    height: calc(100vh);
    padding-top: 90px;
  }
}

@media only screen and (max-width: 600px) {
  .responsive-container {
    margin-top: -50px;
  }
  .stn-box {
    width: auto;
  }
}
