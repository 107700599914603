.parallax-container {
  position: fixed;

  width: 100vw;
  height: 100vh;
  margin: auto;
  z-index: -1;
}
.parallax {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: 0.5s;
}

/* .parallax-dark {
  filter: grayscale(30%) brightness(0.8);
} */

.home-header {
  /* background-color: rgba(42, 42, 42, 0.35); */
  padding: 18px 6px 2px 6px;
  border-radius: 50px;
  /* color: #ffffff; */
  /* box-shadow: 0 0 20px 20px rgba(42, 42, 42, 0.35); */
}
.txt-button {
  padding: 0;
  min-width: 0;
}
.txt-button:hover {
  background-color: transparent;
}
.hover-button {
  background-color: rgb(254, 254, 254);
  border-radius: 50px;
  width: 200px;
  font-weight: bold;
}
.hover-button:hover {
  background-color: #f4bc5a;
  color: #e4f9f5;
}

.hover-button-contained {
  background-color: #f4bc5a;
  border-radius: 25px;
  width: 200px;
  font-weight: bold;
  color: #e4f9f5;
}
.hover-button-contained:hover {
  background-color: #74002e;
}

.landing {
  display: flex;
  justify-content: flex-start;
  align-items: top;
  flex-direction: column;
  text-align: center;
  margin: auto;
  width: 80vw;
  padding-top: calc(90px + 1.5rem);
  line-height: 4rem;
  z-index: 1000;
  height: 75vh;
}

.mv-border {
  background: url(../../assets/images/mv-border.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100px;
  width: auto;
  margin-top: 5rem;
  margin: auto;
  z-index: 1;
}

.flexbox-centered-all {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  padding: 0px 70px;
  transition: 0.3s;
}
.rsvp-container {
  border: 1px solid #f4bc5a;
  border-radius: 25px;
  box-shadow: 0px 0px 5px #f4bc5a;
  background-color: rgba(253, 253, 253, 0.95);
  /* min-height: 340px; */
  transition: 0.3s;
}

.rsvp-options {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  align-items: left;
  padding: 1rem 0rem 2rem 0rem;
  margin: auto;
  border-top: 2px solid #f4bc5a;
}
.r-img {
  width: 500px;
}
.sd-container {
  /* min-height: 70vh; */
  max-width: 600px;
  color: #000;
  transition: 0.3s;
}
.swirl-border {
  background: url(../../assets/images/border.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 30px;
  width: auto;
}

.gr-1 {
  background-image: linear-gradient(
    rgba(253, 253, 253, 0),
    rgba(253, 253, 253, 0.4)
  );
}
.gr-2 {
  background-image: linear-gradient(
    rgba(253, 253, 253, 0.4),
    rgba(253, 253, 253, 0.7) 60%,
    rgba(253, 253, 253, 0.98)
  );
}

@keyframes spinner {
  50% {
    transform: rotateY(36deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

.mirror {
  background: url("../../assets/images/mirror.png");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 275px;
  min-width: 275px;
  margin: 2rem 0rem 2rem 1rem;
  -webkit-animation-name: spinner;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 6s;

  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 6s;

  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

@media screen and (min-width: 600px) {
  .landing {
    line-height: 5rem;
  }
}

@media only screen and (max-width: 900px) {
  .flexbox-centered-all {
    padding: 0px 32px;
  }
  .r-img {
    width: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .flexbox-centered-all {
    padding: 0px 8px;
  }
  .landing {
    padding-top: calc(50px + 1.5rem);
  }
}
