/* Underline styles */
.navLink {
  display: block;
  position: relative;
  padding: 0.2em 0;
  text-transform: uppercase;
  text-decoration: none;
  overflow: hidden;
  color: rgb(244, 188, 90);
}

.navLink:hover {
  /* font-weight: bold; */
  /* color: #74002e; */
  color: rgb(244, 188, 90);
}

/* Fade in */
.navLink::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -1px;
  width: 100%;
  height: 0.15em;
  background-color: rgb(244, 188, 90);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.navLink:hover::after,
.navLink:focus::after {
  /* opacity: 1;
  transform: translate3d(0, 0.2em, 0); */
  transform: translate3d(0, 0, 0);
}

.navLink-dark {
  color: #fff;
}
.navLink-dark:hover {
  color: #fff;
}
.navLink-dark:after {
  background-color: #fff;
}
